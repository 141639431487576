import React, { useState } from 'react'
import Menu from 'react-burger-menu/lib/menus/slide'
import { Link } from 'gatsby'
import styled from 'styled-components'

const MenuStyles = styled.div`
    /* Individual item */
    .bm-item {
      display: inline-block;
      /* Our sidebar item styling */
      text-decoration: none;
      margin-bottom: 10px;
      color: #d1d1d1;
      transition: color 0.2s;
    }
    /* Change color on hover */
    .bm-item:hover {
      color: white;
    }
    /* The rest copied directly from react-burger-menu docs */
    /* Position and sizing of burger button */
    .bm-burger-button {
      position: fixed;
      width: 30px;
      height: 30px;
      right: 17px;
      top: 17px;
      z-index: 1001 !important;
    }
    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background: white;
      border-radius: 2px;
    }
    /* Position and sizing of clickable cross button */
    .bm-cross-button {
      height: 24px;
      width: 24px;
    }
    /* Color/shape of close button cross */
    .bm-cross {
      background: #f1faee;
    }
    /* General sidebar styles */
    .bm-menu {
      background: #1d3557;
      padding: 2.5em 1.5em 0;
      font-size: 1.15em;
    }
    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
      fill: #373a47;
    }
    /* Wrapper for item list */
    .bm-item-list {
      color: #f1faee;
    }
    /* Styling of overlay */
    .bm-overlay {
      background: rgba(0, 0, 0, 0.3);
    }

    @media(min-width: 720px) {
      display: none;
    }
`

export default function SideMenu({ site, navClass }) {
    const [menuState, setMenuOpen] = useState({ menuOpen: true })
    console.dir(site.navigation)
    const items = []

    site.navigation.map((navItem, i) => {
        if (navItem.url.match(/^\s?http(s?)/gi)) {
            items.push(<li key={i} className={`nav-${navItem.label.toLowerCase()}`} role="menuitem"><a className={navClass} href={navItem.url} target="_blank" rel="noopener noreferrer">{navItem.label}</a></li>)
        } else {
            items.push(<li key={i} className={`nav-${navItem.label.toLowerCase()}`} role="menuitem"><Link className={navClass} to={navItem.url}>{navItem.label}</Link></li>)
        }
    })
    const closeMenu = () => {
        setMenuOpen({ menuOpen: false })
    }
    return (
        <MenuStyles>
        	<Menu position="right" isOpen={menuState.isOpen} width={300} disableAutoFocus>
                <ul className="nav" role="menu">
                    {items}
                    <li className="nav-kontakt">
                        <Link to="/kontakt/" className={navClass}>Kontakt</Link>
                    </li>
                </ul>
        	</Menu>
        </MenuStyles>
    )
}
